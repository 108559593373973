import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import algoliasearch, { SearchClient } from 'algoliasearch';
import {
  InstantSearchConfig,
  SearchParameters,
} from 'angular-instantsearch/instantsearch/instantsearch';
import { uniqBy } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  from,
  of,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  mergeMap,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { StudentUnitScoreDialogComponent } from 'src/app/components/student-unit-score-dialog/student-unit-score-dialog.component';
import { EntityType } from 'src/app/enums/entity-type';
import { educationTrackFilter } from 'src/app/helpers/education-track-filter';
import { constants } from 'src/app/misc/constants';
import { Organization } from 'src/app/models/organization';
import { UserReference } from 'src/app/models/user';
import { DatabaseService, UserFilter } from 'src/app/services/database.service';
import { TeacherDashboardActions } from 'src/app/store/actions';
import { setStudentSortCriteria } from 'src/app/store/actions/teacher-dashboard.actions';
import { selectEducationTrack } from 'src/app/store/reducers/shared.reducer';
import {
  StudentSortCriteria,
  selectTeacherDashboardIsSearching,
  selectTeacherDashboardSortCriteria,
  selectTeacherDashboardTableData,
} from 'src/app/store/reducers/teacher-dashboard.reducer';
import { environment } from 'src/environments/environment';
import {
  StudentWithProject,
  TeacherDashboardScore,
  TeacherDashboardTableDataGrouped,
  TeacherDashboardTableRow,
  TeacherDashboardTableRowWithGroupedScores,
  TeacherDashboardUnitScore,
  isTeacherDashboardUnitTupleScore,
} from '../../../../shared/models/teacher-dashboard-models';
import { utility } from '../../helpers/utility';
import { Domain } from '../../models/domain';
import { Group } from '../../models/group';
import { Project } from '../../models/project';
import { StructuralEntity } from '../../models/structural-entity';
import { DbUser, buildUserRef, getUserRef } from '../../models/user';
import { StructureService } from '../../services/structure.service';
import { AppState } from '../../store/reducers';
import {
  selectAlgoliaUserKey,
  selectUser,
  selectUserFavoriteGroups,
  selectUserOrganizationId,
} from '../../store/reducers/user.reducer';
import { SearchQuery } from '../auto-complete/auto-complete.component';
import { StudentDetailComponent } from '../student-detail/student-detail.component';

type SortOption = StudentSortCriteria & { label: string };

@Component({
  selector: 'app-dashboard',
  templateUrl: './teacher-dashboard.component.html',
  styleUrls: ['./teacher-dashboard.component.scss'],
})
export class TeacherDashboardComponent implements OnInit, OnDestroy {
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  userOrganizationId$: Observable<Organization['id']>;
  teacherRef$: Observable<UserReference>;
  groups$: Observable<Group[]>;
  students$: Observable<StudentWithProject[]>;
  teacherDashboardTableData$: Observable<TeacherDashboardTableDataGrouped>;
  displayedColumns: Array<keyof TeacherDashboardTableRow> = [
    'name',
    'groups',
    'timeTotal',
    'timeOverSelectionString',
    'scores',
  ];
  projects$: Observable<Project[]>;
  domains$: Observable<StructuralEntity[]>;

  selectedProjectId = new FormControl<Project['id']>(null);
  selectedDomain = new FormControl<{
    id: Domain['id'];
    title: Domain['title'];
  }>(null);
  selectedGroup = new FormControl<Group>(null);
  selectedUsers$: BehaviorSubject<UserReference[]> = new BehaviorSubject([]);

  startDate = new FormControl<Date>(null);
  startDateTimestamp$: Observable<number>;
  endDate = new FormControl<Date>(null);
  endDateTimestamp$: Observable<number>;

  search$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  isSearching$: Observable<boolean>;

  chapterTitles: {
    [projectId: string]: Observable<string[]>;
  } = {};

  unitTitles: {
    [chapterId: string]: Observable<string[]>;
  } = {};

  searchedDomain: {
    id: Domain['id'];
    title: Domain['title'];
  };

  userSearchClient: SearchClient;
  userSearchConfig: InstantSearchConfig;
  searchParameters: SearchParameters;

  sortCriteria$: Observable<StudentSortCriteria | null>;
  sortOptionsName: SortOption[] = [
    {
      field: 'firstName',
      order: 'asc',
      label: 'Sorteer op voornaam (oplopend)',
    },
    {
      field: 'firstName',
      order: 'desc',
      label: 'Sorteer op voornaam (aflopend)',
    },
    {
      field: 'lastName',
      order: 'asc',
      label: 'Sorteer op achternaam (oplopend)',
    },
    {
      field: 'lastName',
      order: 'desc',
      label: 'Sorteer op achternaam (aflopend)',
    },
  ];

  sortOptionsTimeTotal: SortOption[] = [
    {
      field: 'timeTotal',
      order: 'asc',
      label: 'Sorteer op totale tijd (oplopend)',
    },
    {
      field: 'timeTotal',
      order: 'desc',
      label: 'Sorteer op totale tijd (aflopend)',
    },
  ];

  sortOptionsTimeOverSelection: SortOption[] = [
    {
      field: 'timeOverSelectionString',
      order: 'asc',
      label: 'Sorteer op tijd over selectie (oplopend)',
    },
    {
      field: 'timeOverSelectionString',
      order: 'desc',
      label: 'Sorteer op tijd over selectie (aflopend)',
    },
  ];

  favoriteGroups$: Observable<Group['id'][]>;

  constructor(
    private store: Store<AppState>,
    private databaseService: DatabaseService,
    private structureService: StructureService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.userOrganizationId$ = this.store
      .select(selectUserOrganizationId)
      .pipe(takeUntil(this.ngDestroyed$));

    this.projects$ = combineLatest([
      this.store.select(selectUser),
      this.store.select(selectEducationTrack),
    ]).pipe(
      switchMap(([user, educationTrack]) => {
        if (!user) {
          return of(null);
        }
        return this.databaseService
          .getProjectsForUser(user)
          .pipe(
            map((entities) => educationTrackFilter(entities, educationTrack))
          );
      }),
      shareReplay(1),
      takeUntil(this.ngDestroyed$)
    );

    this.domains$ = this.selectedProjectId.valueChanges.pipe(
      switchMap((projectId) => {
        if (!projectId) {
          return of(null);
        }
        return this.structureService.getChildrenForEntity(
          projectId,
          EntityType.project
        );
      }),
      shareReplay(1),
      takeUntil(this.ngDestroyed$)
    );

    // Filter defaults
    this.startDate.setValue(
      new Date(new Date().setDate(new Date().getDate() - 7))
    );

    this.isSearching$ = this.store.select(selectTeacherDashboardIsSearching);

    this.startDateTimestamp$ = this.startDate.valueChanges.pipe(
      startWith(this.startDate.value),
      debounceTime(1000),
      map((date: Date) => date?.valueOf()),
      takeUntil(this.ngDestroyed$)
    );

    this.endDateTimestamp$ = this.endDate.valueChanges.pipe(
      startWith(this.endDate.value),
      debounceTime(1000),
      map((date: Date) => date?.valueOf()),
      takeUntil(this.ngDestroyed$)
    );

    // Link datepickers.
    let lastStartDate: number;
    combineLatest([this.startDateTimestamp$, this.endDateTimestamp$])
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe(([startDate, endDate]) => {
        if (utility.allAreTruthy([startDate, endDate]) && startDate > endDate) {
          if (lastStartDate === startDate) {
            this.startDate.setValue(new Date(endDate));
          } else {
            this.endDate.setValue(new Date(startDate));
          }
        }

        lastStartDate = startDate;
      });

    // Load groups
    this.groups$ = this.userOrganizationId$.pipe(
      switchMap((organizationId) => {
        if (!organizationId) {
          return of(null);
        }
        return combineLatest([
          this.databaseService.getGroupsForOrg(organizationId),
          this.favoriteGroups$,
        ]).pipe(
          map(([groups, favoriteGroups]) =>
            // Sort groups: favorites first, then alphabetically
            groups.sort((a, b) => {
              const aIsFavorite = favoriteGroups.includes(a.id);
              const bIsFavorite = favoriteGroups.includes(b.id);

              if (aIsFavorite && !bIsFavorite) {
                return -1;
              }
              if (!aIsFavorite && bIsFavorite) {
                return 1;
              }

              // If both are favorite or both are not, sort alphabetically
              return a.name.localeCompare(b.name);
            })
          )
        );
      }),
      shareReplay(1),
      takeUntil(this.ngDestroyed$)
    );

    // Set the current user as teacher
    this.teacherRef$ = this.store.select(selectUser).pipe(
      filter(utility.isTruthy),
      map((user) => getUserRef(user)),
      takeUntil(this.ngDestroyed$)
    );

    // Init user search
    combineLatest([
      this.store.select(selectAlgoliaUserKey),
      this.userOrganizationId$,
    ])
      .pipe(first(utility.allAreTruthy), takeUntil(this.ngDestroyed$))
      .subscribe(([algoliaUserKey, userOrganizationId]) => {
        this.userSearchClient = algoliasearch(
          environment.algoliaAppId,
          algoliaUserKey
        );
        this.userSearchConfig = {
          indexName: constants.algoliaIndices.users,
          searchClient: this.userSearchClient,
        };

        this.searchParameters = {
          filters: `organizationId:${userOrganizationId}`,
        } as SearchParameters; // TODO something weird with types here.
      });

    // All the student magic
    this.students$ = combineLatest([
      this.userOrganizationId$,
      this.teacherRef$,
      this.selectedGroup.valueChanges.pipe(
        startWith(this.selectedGroup.value)
      ) as Observable<Group | null>,
      this.selectedUsers$,
    ]).pipe(
      switchMap(
        ([userOrganizationId, teacherRef, selectedGroup, selectedUserIds]) => {
          if (!userOrganizationId) {
            return of([]);
          }

          // Filter by teacher only if no group is provided.
          const userFilter: UserFilter = {
            uids:
              (selectedUserIds?.length && selectedUserIds.map((u) => u.uid)) ||
              null,
            organizationId: userOrganizationId,
            teacher: selectedGroup ? null : teacherRef,
            group: selectedGroup && {
              id: selectedGroup.id,
              name: selectedGroup.name,
            },
          };
          return this.databaseService.getUsers(userFilter).pipe(first());
        }
      ),
      map(async (students: DbUser[]) => {
        const studentsWithProject: StudentWithProject[] = [];
        for (const student of students) {
          const lastAttempt = await this.databaseService
            .getLastUserInteractionAttempt(student.uid)
            .toPromise();
          studentsWithProject.push({
            ...student,
            lastProjectId: lastAttempt?.projectId,
          });
        }
        return studentsWithProject;
      }),
      mergeMap((promise) => from(promise)),
      shareReplay(1),
      takeUntil(this.ngDestroyed$)
    );

    // Reset domain after project change
    this.selectedProjectId.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.ngDestroyed$))
      .subscribe(() => this.selectedDomain.setValue(null));

    // The actual data for the tables, grouped by project
    this.teacherDashboardTableData$ = this.store.select(
      selectTeacherDashboardTableData
    );

    // Pre-fetch all chapter titles
    this.projects$.pipe(takeUntil(this.ngDestroyed$)).subscribe((projects) => {
      for (const project of projects) {
        this.chapterTitles[project.id] = this.getTitlesForChildrenOfChildren(
          project.id,
          EntityType.project
        );
      }
    });

    // Get unit titles when selecting
    this.selectedDomain.valueChanges
      .pipe(
        filter((domain) => !!domain),
        distinctUntilChanged(),
        takeUntil(this.ngDestroyed$)
      )
      .subscribe((domain) => {
        this.unitTitles[domain.id] = this.getTitlesForChildrenOfChildren(
          domain.id,
          EntityType.domain
        );
      });

    // Hook up all filters to NGRX
    this.startDateTimestamp$.subscribe((startDate) =>
      this.store.dispatch(
        TeacherDashboardActions.selectStartDate({ startDate })
      )
    );

    this.endDateTimestamp$.subscribe((endDate) =>
      this.store.dispatch(TeacherDashboardActions.selectEndDate({ endDate }))
    );

    this.selectedProjectId.valueChanges.subscribe((selectedProjectId) =>
      this.store.dispatch(
        TeacherDashboardActions.selectProject({ selectedProjectId })
      )
    );

    this.selectedDomain.valueChanges.subscribe((selectedDomain) =>
      this.store.dispatch(
        TeacherDashboardActions.selectDomain({ selectedDomain })
      )
    );

    this.selectedGroup.valueChanges.subscribe((selectedGroup) =>
      this.store.dispatch(
        TeacherDashboardActions.selectGroup({ selectedGroup })
      )
    );

    this.selectedUsers$.subscribe((selectedUsers) =>
      this.store.dispatch(
        TeacherDashboardActions.selectUsers({ users: selectedUsers })
      )
    );

    // Table sorting
    this.sortCriteria$ = this.store.select(selectTeacherDashboardSortCriteria);

    this.favoriteGroups$ = this.store.select(selectUserFavoriteGroups);
  }

  clearStartDate() {
    this.startDate.setValue(null);
  }

  clearEndDate() {
    this.endDate.setValue(null);
  }

  selectUser(query: SearchQuery) {
    if (query.id) {
      const user: UserReference = buildUserRef(query.id, query.query);
      this.selectedUsers$.next(
        uniqBy([...this.selectedUsers$.value, user], 'uid').slice(-10)
      );

      // TODO clear input
    }
  }

  removeUser(uid: string) {
    this.selectedUsers$.next(
      this.selectedUsers$.value.filter((user) => user.uid !== uid)
    );
  }

  studentDetailDialog(uid: DbUser['uid'], name: DbUser['name']) {
    this.dialog.open(StudentDetailComponent, {
      data: buildUserRef(uid, name),
      autoFocus: false,
      minWidth: '50vw',
      minHeight: '50vh',
    });
  }

  search() {
    this.store.dispatch(TeacherDashboardActions.performSearch());
    this.searchedDomain = this.selectedDomain.value;
  }

  zoomIn(scoreGroup: TeacherDashboardScore[]): void {
    const score = scoreGroup[0];

    if (isTeacherDashboardUnitTupleScore(score)) {
      return;
    }

    const { projectId, domainId } = score[0];

    this.selectedProjectId.setValue(projectId);

    this.domains$
      .pipe(
        filter(
          (domains) =>
            domains !== null && domains.some((domain) => domain.id === domainId)
        ),
        take(1),
        takeUntil(this.ngDestroyed$)
      )
      .subscribe((domains) => {
        const domainToSelect = domains.find((domain) => domain.id === domainId);
        if (domainToSelect) {
          this.selectedDomain.setValue({
            id: domainToSelect.id,
            title: domainToSelect.title,
          });

          this.search();
        }
      });
  }

  setSortCriteria(criteria: StudentSortCriteria) {
    this.store.dispatch(setStudentSortCriteria({ criteria }));
  }

  isSortActive(
    criteria: StudentSortCriteria,
    field: StudentSortCriteria['field'],
    order: StudentSortCriteria['order']
  ): boolean {
    return criteria?.field === field && criteria?.order === order;
  }

  async openUnitDialog(
    row: TeacherDashboardUnitScore,
    rows: TeacherDashboardTableRowWithGroupedScores[]
  ) {
    const unit = await this.structureService.getUnit(row.unitId).toPromise();

    const userUnitScores = [];

    for (const r of rows) {
      const maxStamp = Math.max(
        ...r.scores.flat().map((score) => score[0].timestamp)
      );

      userUnitScores.push({
        name: r.name,
        score: await this.databaseService
          .getUserScoreForEntity({
            uid: r.uid,
            projectId: row.projectId,
            domainId: row.domainId,
            chapterId: row.chapterId,
            unitId: row.unitId,
            maxTimestamp: maxStamp,
          })
          .toPromise(),
      });
    }

    const dialogRef = this.dialog.open(StudentUnitScoreDialogComponent, {
      data: { unit, userUnitScores },
    });
  }

  ngOnDestroy() {
    this.store.dispatch(TeacherDashboardActions.resetTeacherDashboard());
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }

  compareDomains(
    domain1: { id: Domain['id']; title: Domain['title'] },
    domain2: { id: Domain['id']; title: Domain['title'] }
  ): boolean {
    return domain1 && domain2 ? domain1.id === domain2.id : domain1 === domain2;
  }

  toggleFavoriteGroup(groupId: string, $event: MouseEvent) {
    $event.stopPropagation();

    this.favoriteGroups$.pipe(take(1)).subscribe((favoriteGroups) => {
      if (favoriteGroups.includes(groupId)) {
        this.databaseService.removeFavoriteGroup(groupId);
      } else {
        this.databaseService.addFavoriteGroup(groupId);
      }
    });
  }

  private getTitlesForChildrenOfChildren(
    entityId: string,
    entityType: EntityType
  ): Observable<string[]> {
    if (!entityId) {
      return of(null);
    }

    return this.structureService
      .getChildrenForEntity(entityId, entityType)
      .pipe(
        debounceTime(50),
        switchMap((children) =>
          this.structureService.getChildrenForEntities(
            children as StructuralEntity[]
          )
        ),
        map((entitiesArrays: StructuralEntity[][]) => {
          entitiesArrays.forEach((entities, i) => {
            if (i !== entitiesArrays.length - 1) {
              // Add a special divider
              entities.push({ title: '|' } as any);
            }
          });

          return entitiesArrays
            .flat()
            .map((entity) => entity.shortTitle || entity.title);
        }),
        shareReplay(1),
        takeUntil(this.ngDestroyed$)
      );
  }
}
