<ng-container *ngIf="flavor$ | async as flavor">
    <mat-card>
        <mat-card-header *ngIf="examSession$| async as examSession">
            <mat-card-title>Toets {{ examSession.examTitle }}</mat-card-title>
            <mat-card-subtitle>
                {{ examSession.examTitle }}
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>

            <ng-container *ngIf="flavor === 'end'; else examInstructions">
                <p>
                    Klik hier als je de toets wil inleveren.
                </p>

                <ng-container *ngIf="unansweredQuestionsCount$ | async as unansweredCount">
                    <p class="text-warn">
                        LET OP: Er zijn nog {{ unansweredCount }} onbeantwoorde vragen!
                    </p>
                </ng-container>
            </ng-container>
            <ng-template #examInstructions>
                <p class="exam-instructions">
                    {{ (exam$ | async)?.instructions }}
                </p>
            </ng-template>

            <ng-container *ngIf="isDevMode | async">
                <div class="grid-item">
                    Exam ID: {{ (exam$ | async)?.id }}
                </div>
                <div class="grid-item">
                    Exam desc: {{ (exam$ | async)?.description }}
                </div>
                <!-- <div class="grid-item">
                    Exam instance ID: {{ (examInstance$ | async)?.id }}
                </div> -->
                <ng-container *ngIf="examSession$ | async as examSession">
                    <div class="grid-item">
                        Exam session ID: {{ examSession.id }}
                    </div>
                    <div class="grid-item">
                        Exam session startedAt: {{ examSession.startedAt | date: 'dd-MM-yyyy HH:mm'}}
                    </div>
                    <div class="grid-item">
                        Exam session finishedAt:
                        {{ isFinalizedExamSession(examSession) ? examSession.finishedAt : 'Not finished yet' }}
                    </div>
                    <div class="grid-item">
                        Exam session open until: {{ examSession.openUntil | date: 'dd-MM-yyyy HH:mm' }}
                    </div>
                </ng-container>
                <div class="grid-item">
                    Tijd: {{ (exam$ | async)?.timeLimitMinutes }} minuten
                </div>
                <div class="grid-item">
                    Smaak: {{ flavor }}
                </div>
            </ng-container>

            <mat-card-actions align="end">
                <div class="action">

                    <app-button-with-spinner *ngIf="showStartButton$ | async" [isLoading$]="isSessionStarting$"
                        (click)="startExam()" color="primary">
                        Start toets
                    </app-button-with-spinner>

                    <button *ngIf="showContinueButton$ | async" mat-raised-button color="primary"
                        (click)="resumeExam()">
                        Verder gaan
                    </button>
                </div>

                <div class="action" *ngIf="showSubmitButton$ | async">
                    <button mat-raised-button color="primary" (click)="submitExam()">
                        Inleveren
                    </button>
                </div>
            </mat-card-actions>

        </mat-card-content>
    </mat-card>
</ng-container>