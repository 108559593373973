import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject, combineLatest, from, of } from 'rxjs';
import {
  catchError,
  finalize,
  first,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { Exam } from 'shared/models/exam';
import { ExamInstance } from 'shared/models/exam-instance';
import { ExamService } from 'src/app/services/exam.service';
import { EducationTrack } from '../../../models/project';
import { AppState } from '../../../store/reducers/index';
import { selectEducationTrack } from '../../../store/reducers/shared.reducer';

type ExamInstanceRow = ExamInstance & {
  finishedExamSessions: number;
  examName: string;
};

@Component({
  selector: 'app-exam-overview-teacher',
  templateUrl: './exam-overview-teacher.component.html',
  styleUrls: ['./exam-overview-teacher.component.scss'],
})
export class ExamOverviewTeacherComponent implements OnInit, OnDestroy {
  isLoading = false;
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  examResultColumns = [
    {
      field: 'examName',
      title: 'Toets',
    },
    {
      field: 'customTitle',
      title: 'Titel',
    },
    {
      field: 'openFrom',
      title: 'Datum van',
      type: 'date',
    },
    {
      field: 'openTo',
      title: 'Datum tot',
      type: 'date',
    },
    {
      field: 'finishedExamSessions',
      title: 'Aantal gemaakt',
    },
    {
      field: 'teacherId',
      title: 'Docent',
      type: 'user',
    },
    {
      field: 'passphrase',
      title: 'Wachtwoord',
    },
    {
      field: 'duration',
      title: 'Duur',
      type: 'duration',
    },
    {
      field: 'actions',
      title: 'Acties',
      type: 'actions',
    },
  ];

  examResultDisplayedColumns = this.examResultColumns.map((c) => c.field);

  examColumns = [
    {
      field: 'name',
      title: 'Toets',
    },
    {
      field: 'actions',
      title: 'Acties',
    },
  ];

  examDisplayedColumns = this.examColumns.map((c) => c.field);

  examResults$: Observable<ExamInstanceRow[]>;
  exams$: Observable<Exam[]>;

  educationTrack$: Observable<EducationTrack>;

  constructor(
    private examService: ExamService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.isLoading = true;

    const activeExamInstances$ = from(
      this.examService.getActiveExamInstances()
    );

    this.educationTrack$ = this.store.select(selectEducationTrack);

    this.exams$ = this.educationTrack$.pipe(
      first(),
      switchMap((eduTrack) => this.examService.getExams(eduTrack))
    );
    this.examResults$ = combineLatest([activeExamInstances$, this.exams$]).pipe(
      map(([examResults, exams]) =>
        examResults.map((result) => ({
          ...result,
          examName: exams.find((exam) => exam.id === result.examId)?.name,
        }))
      ),
      catchError((error) => {
        console.error('Error fetching exam results:', error);
        return of([]);
      }),
      finalize(() => {
        this.isLoading = false;
      }),
      takeUntil(this.ngDestroyed$)
    );
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }
}
