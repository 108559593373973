import { isString } from 'lodash';
import { Timestamp } from '../../src/app/helpers/time-helper';
import { utility } from '../../src/app/helpers/utility';
import { NotepadLineForStorage } from '../../src/app/models/notepad-line';
import { Organization } from '../../src/app/models/organization';
import { DbUser } from '../../src/app/models/user';
import { StandaloneInteractionAttempt } from '../../src/app/models/user-interaction-attempt';
import { Modify } from '../../src/app/types/utility-types';
import { Exam, ExamInteraction, ExamSection } from './exam';
import { ExamInstance } from './exam-instance';

export type ExamSession = ExamSessionNotSubmitted | ExamSessionFinalized;

export type DbExamSession = Omit<ExamSession, 'id'>;

export type ExamSessionNotSubmitted = {
  id: string;
  examId: Exam['id'];
  examTitle: Exam['name'];
  examVersion: Exam['version'];
  examInstanceId: ExamInstance['id'];
  examInstanceCustomTitle: ExamInstance['customTitle'];
  uid: DbUser['uid'];
  organizationId: Organization['id'];
  startedAt?: Timestamp;
  sections: ExamSessionSections;
  openUntil: Timestamp;
  hideGrade: Exam['hideGrade'];
};

export type ExamSessionScore = {
  totalScore: number;
  scorePerLabel: Record<string, number>;
  maxTotalScore: number;
  maxScorePerLabel: Record<string, number>;
};

export type ExamSessionFinalized = Modify<
  ExamSessionNotSubmitted,
  ExamSessionScore
> & {
  finishedAt: Timestamp;
  startedAt: Timestamp;
};

export type ExamSessionSections = {
  [index: number]: ExamSessionSection;
};

export type ExamSessionSection = Modify<
  ExamSection,
  {
    interactions: ExamSessionSectionsInteractions;
  }
>;

export type ExamSessionSectionsInteractions = {
  [index: number]: ExamSessionInteractionAttempt;
};

export type ExamSessionInteractionAttempt = {
  interactionId: ExamInteraction['interactionId'];
  interactionVersion: StandaloneInteractionAttempt['interactionVersion'];
  answers: StandaloneInteractionAttempt['answers'];
  score: StandaloneInteractionAttempt['score'];
  varsShuffledIndexes: StandaloneInteractionAttempt['varsShuffledIndexes'];
  timestamp: StandaloneInteractionAttempt['timestamp'];
  timeTaken: StandaloneInteractionAttempt['timeTaken'];
  crypto?: never;
  notepadLines?: NotepadLineForStorage[];
};

export type ExamSessionInteractionAttemptWithoutTimeTaken = Omit<
  ExamSessionInteractionAttempt,
  'timeTaken' | 'timestamp'
>;

// We use this to send over the line to make it harder to temper with the payload.
export type ExamSessionInteractionAttemptEncrypted = Omit<
  ExamSessionInteractionAttempt,
  'score' | 'crypto'
> & {
  crypto: string; // Hash of the interaction attempt including the score.
};

export const isExamSessionInteractionAttempt = (
  attempt: ExamSessionInteractionAttempt
): attempt is ExamSessionInteractionAttempt =>
  utility.isObject(attempt) &&
  isString(attempt.interactionVersion) &&
  Array.isArray(attempt.answers) &&
  attempt.answers.every(isString) &&
  isFinite(attempt.score) &&
  (attempt.varsShuffledIndexes === undefined ||
    (Array.isArray(attempt.varsShuffledIndexes) &&
      attempt.varsShuffledIndexes.every(isFinite))) &&
  isFinite(attempt.timestamp) &&
  isFinite(attempt.timeTaken);

export const isExamSessionInteractionAttemptEncrypted = (
  attempt: ExamSessionInteractionAttemptEncrypted
): attempt is ExamSessionInteractionAttemptEncrypted =>
  utility.isObject(attempt) &&
  isString(attempt.interactionVersion) &&
  Array.isArray(attempt.answers) &&
  attempt.answers.every(isString) &&
  isFinite(attempt.timestamp) &&
  isFinite(attempt.timeTaken) &&
  isString(attempt.crypto);

export const isFinalizedExamSession = (
  examSession: ExamSessionFinalized,
  now: Timestamp
): examSession is ExamSessionFinalized =>
  (examSession &&
    'finishedAt' in examSession &&
    typeof examSession.finishedAt === 'number') ||
  (typeof examSession.openUntil === 'number' && examSession.openUntil < now);
