<mat-card>
    <mat-card-content>
        <div class="filter-container">
            <mat-form-field appearance="outline">
                <mat-label>Groep</mat-label>
                <mat-select [formControl]="selectedGroup">
                    <mat-select-trigger>
                        {{ selectedGroup.value?.name || 'Alles' }}
                    </mat-select-trigger>
                    <mat-option class="option-empty" [value]="null">Alles</mat-option>
                    <mat-option *ngFor="let group of groups$ | async" [value]="group">
                        <div class="group-option">
                            {{ group.name }}
                            <mat-icon class="favorite-icon"
                                [class.favorited]="(favoriteGroups$ | async)?.includes(group.id)"
                                (click)="toggleFavoriteGroup(group.id, $event)">
                                {{ (favoriteGroups$ | async)?.includes(group.id) ? 'star' : 'star_border' }}
                            </mat-icon>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Project</mat-label>
                <mat-select [formControl]="selectedProjectId">
                    <mat-option class="option-empty" [value]="null">Alles</mat-option>
                    <mat-option *ngFor="let project of projects$ | async" [value]="project.id">
                        {{ project.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Domein</mat-label>
                <mat-select [attr.disabled]="!selectedProjectId.value" [formControl]="selectedDomain"
                    [compareWith]="compareDomains">
                    <mat-option class="option-empty" [value]="null">Alles</mat-option>
                    <mat-option *ngFor="let domain of domains$ | async" [value]="{id: domain.id, title: domain.title}">
                        {{ domain.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="datepicker filter" appearance="outline">
                <mat-label>Van</mat-label>
                <input autocomplete="off" matInput [formControl]="startDate" [matDatepicker]="pickerStart">
                <mat-datepicker-toggle *ngIf="startDate.value" matSuffix (click)="clearStartDate()">
                    <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                <mat-datepicker #pickerStart></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="datepicker filter" appearance="outline">
                <mat-label>Tot en met</mat-label>
                <input autocomplete="off" matInput [formControl]="endDate" [matDatepicker]="pickerEnd">
                <mat-datepicker-toggle *ngIf="endDate.value" matSuffix (click)="clearEndDate()">
                    <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                <mat-datepicker #pickerEnd></mat-datepicker>
            </mat-form-field>
            <div class="user-filter-wrapper">
                <ais-instantsearch class="filter" *ngIf="userSearchConfig" [config]="userSearchConfig">
                    <app-auto-complete [label]="'Student'" (outputSelected)="selectUser($event)"></app-auto-complete>
                    <ais-configure [searchParameters]="searchParameters"></ais-configure>
                </ais-instantsearch>
                <mat-chip-list class="mat-form-field-wrapper">
                    <mat-chip *ngFor="let user of selectedUsers$ | async">
                        {{ user.name }}
                        <mat-icon (click)="removeUser(user.uid)" matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
            <div class="filter">
                <app-button-with-spinner icon="search" (click)="search()"
                    [isLoading$]="isSearching$">Zoeken</app-button-with-spinner>
            </div>
        </div>

        <div class="all-dashboards-wrapper">
            <ng-container *ngFor="let projectData of teacherDashboardTableData$ | async">
                <div class="dashboard-header">
                    <h4>{{ projectData.projectTitle }}</h4>
                    <h4 class="domain-title" *ngIf="searchedDomain">
                        {{ searchedDomain.title }}
                    </h4>
                </div>
                <table mat-table [dataSource]="projectData.tableRows">
                    <ng-container matColumnDef="name" sticky>
                        <th mat-header-cell *matHeaderCellDef>
                            <div class="sticky-column-cover"></div>
                            <div class="description-sort-btn-wrapper">
                                <div>Naam</div>
                                <mat-menu #sortMenuName="matMenu">
                                    <button mat-menu-item *ngFor="let option of sortOptionsName"
                                        [class.bold]="isSortActive(sortCriteria$ | async, option.field, option.order)"
                                        (click)="setSortCriteria(option)">
                                        {{ option.label }}
                                    </button>
                                </mat-menu>
                                <button class="sort-btn" mat-icon-button [matMenuTriggerFor]="sortMenuName">
                                    <mat-icon>sort</mat-icon>
                                </button>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <a (click)="studentDetailDialog(element.uid, element.name)" href="javascript:void(0)"
                                class="student-dialog">
                                {{ element.name }}
                            </a>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="groups" sticky>
                        <th mat-header-cell *matHeaderCellDef>
                            <div class="sticky-column-cover"></div>
                            Groep
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.groups }} </td>
                    </ng-container>
                    <ng-container matColumnDef="timeTotal" sticky>
                        <th mat-header-cell *matHeaderCellDef>
                            <div class="sticky-column-cover"></div>
                            Uren Totaal
                            <button mat-icon-button [matMenuTriggerFor]="sortMenuTimeTotal">
                                <mat-icon>sort</mat-icon>
                            </button>
                            <mat-menu #sortMenuTimeTotal="matMenu">
                                <button mat-menu-item *ngFor="let option of sortOptionsTimeTotal"
                                    [class.bold]="isSortActive(sortCriteria$ | async, option.field, option.order)"
                                    (click)="setSortCriteria(option)">
                                    {{ option.label }}
                                </button>
                            </mat-menu>
                        </th>
                        <td mat-cell *matCellDef="let element"> {{ element.timeTotal }} </td>
                    </ng-container>
                    <ng-container matColumnDef="timeOverSelectionString" sticky>
                        <th mat-header-cell *matHeaderCellDef>
                            <div class="sticky-column-cover"></div>
                            Uren over selectie
                            <button mat-icon-button [matMenuTriggerFor]="sortMenuTimeOverSelection">
                                <mat-icon>sort</mat-icon>
                            </button>
                            <mat-menu #sortMenuTimeOverSelection="matMenu">
                                <button mat-menu-item *ngFor="let option of sortOptionsTimeOverSelection"
                                    [class.bold]="isSortActive(sortCriteria$ | async, option.field, option.order)"
                                    (click)="setSortCriteria(option)">
                                    {{ option.label }}
                                </button>
                            </mat-menu>
                        </th>
                        <td mat-cell *matCellDef="let element"> {{ element.timeOverSelectionString }} </td>
                    </ng-container>
                    <ng-container matColumnDef="scores">
                        <th mat-header-cell *matHeaderCellDef>
                            <div class="scores-container">
                                <span *ngIf="projectData.projectId" class="rotate pre-text"
                                    [ngClass]="{ 'text-primary': !searchedDomain }">
                                    {{ !!searchedDomain && 'Leereenheden' || 'Hoofdstukken' }}
                                </span>
                                <ng-container
                                    *ngFor="let title of (!!searchedDomain ? (unitTitles[searchedDomain.id] | async) : (chapterTitles[projectData.projectId] | async))">
                                    <span *ngIf="title !== '|'" [attr.title]="title.length > 22 ? title : null"
                                        class="rotate">{{ title | truncate:22 }}</span>
                                    <div *ngIf="title === '|'" class="divider"></div>
                                </ng-container>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="scores-container">
                                <ng-container *ngFor="let scoreGroup of element.scores; let groupIndex = index">
                                    <div class="score-group" [appHighlightTeacherDashboardColumn]="groupIndex"
                                        [unitIndex]="scoreIndex + 100 * groupIndex" (click)="zoomIn(scoreGroup)"
                                        [ngClass]="{ 'pointer': !searchedDomain }"
                                        *ngFor="let userScores of scoreGroup; let scoreIndex = index">
                                        <div
                                            (click)="!!searchedDomain && openUnitDialog(userScores[0], projectData.tableRows)">
                                            <app-teacher-dashboard-score-indicator
                                                [ngClass]="{ 'pointer': !!searchedDomain, 'hover': !!searchedDomain }"
                                                [userScores]="userScores">
                                            </app-teacher-dashboard-score-indicator>
                                        </div>
                                    </div>
                                    <!-- Divider after each group except the last one -->
                                    <div *ngIf="groupIndex < element.scores.length - 1" class="divider">
                                        <hr>
                                    </div>
                                </ng-container>
                                <span *ngIf="!element.scores?.length" class="no-score">Geen scores</span>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </ng-container>
        </div>
        <h4 *ngIf="(teacherDashboardTableData$ | async)?.length === 0">Geen resultaten</h4>

    </mat-card-content>
</mat-card>