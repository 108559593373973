<mat-card>
    <mat-card-header>
        <mat-card-title>
            Gemaakte toetsen
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-table [dataSource]="examResults$ | async">

            <ng-container *ngFor="let column of examResultColumns" [matColumnDef]="column.field">
                <mat-header-cell *matHeaderCellDef>{{ column.title }}</mat-header-cell>
                <mat-cell *matCellDef="let examInstance">

                    <ng-container *ngIf="column.type === 'date'">
                        {{ examInstance[column.field] | date: 'dd-MM-yyyy' }}
                    </ng-container>

                    <ng-container *ngIf="column.type === 'user'">
                        {{ examInstance[column.field] | username | async}}
                    </ng-container>

                    <ng-container *ngIf="column.type === undefined">
                        {{ examInstance[column.field] }}
                    </ng-container>
                    <ng-container *ngIf="column.type === 'duration'">
                        {{ examInstance[column.field] | date: 'HH:mm': "UTC"}}
                    </ng-container>
                    <ng-container *ngIf="column.type === 'actions'">
                        <a *ngIf="examInstance.finishedExamSessions > 0" mat-stroked-button
                            [routerLink]="['/exam-results', examInstance.id]">Inzien</a>
                    </ng-container>

                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="progressBar">
                <mat-header-cell *matHeaderCellDef colspan="4" class="progress-bar-cell">
                    <mat-progress-bar mode="indeterminate" *ngIf="isLoading" color="accent"></mat-progress-bar>
                </mat-header-cell>
            </ng-container>

            <mat-header-row mat-header-row *matHeaderRowDef="examResultDisplayedColumns; sticky: true"
                class="main-header">
            </mat-header-row>
            <mat-header-row *matHeaderRowDef="['progressBar']; sticky: true" class="progress-bar-row">
            </mat-header-row>
            <mat-row mat-row *matRowDef="let row; columns: examResultDisplayedColumns;"></mat-row>
        </mat-table>
    </mat-card-content>
</mat-card>

<mat-card>
    <mat-card-header>
        <mat-card-title>
            Toetsen
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-table [dataSource]="exams$ | async">
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef>Toets</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    {{ row.name }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Acties</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <a mat-stroked-button [routerLink]="['/ready-exam', row.id]">Klaarzetten</a>
                </mat-cell>
            </ng-container>
            <mat-header-row mat-header-row *matHeaderRowDef="examDisplayedColumns; sticky: true"></mat-header-row>
            <mat-row mat-row *matRowDef="let row; columns: examDisplayedColumns;"></mat-row>
        </mat-table>
    </mat-card-content>
</mat-card>